import { configureStore, combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import productsReducer from './productsSlice';
import chatWidgetReducer from './chatWidgetSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  products: productsReducer,
  chatWidget: chatWidgetReducer,
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
    .concat([thunk])
})

export const persistor = persistStore(store);
export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();
export default store;

