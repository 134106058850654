import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { chatWidgetReducer } from '../../redux/chatWidgetSlice';

export default function ChatWidget(){
  const dispatch = useDispatch();
  const chatWidget = useSelector((state)=> state.chatWidget);
 
  useEffect(() => {
    if(chatWidget){
      if (chatWidget.scriptUrl.includes('tawk')) {
        window.Tawk_API = window.Tawk_API || {};
        window.Tawk_LoadStart = new Date();
        loadScript(`${chatWidget.scriptUrl}${chatWidget.propertyId}/${chatWidget.widgetId}`);
      } else if (chatWidget.scriptUrl.includes('jivo')) {
        loadScript(`${chatWidget.scriptUrl}${chatWidget.widgetId}`);
      }
    }else{
        loadChatWidgets();
    }

    // if (!chatWidget) return;
    // if (!chatWidget) {
    //   return; // Exit early if chatWidget is null or undefined
    // }

    return () => {
      if(chatWidget){
        // Cleanup: remove existing script tags if needed
      const scriptTags = document.querySelectorAll('script[src*="tawk.to"], script[src*="jivosite"]');
      scriptTags.forEach((script) => script.remove());
      }
    };
  },[chatWidget]);

  const loadChatWidgets = async () => {
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chat-widget?isPrimary=true`);
      const { error, data } = res.data;

      if(!error && typeof data === 'object' && data.isPrimary){        
        dispatch(chatWidgetReducer(data));
      }
    }catch(err){
      console.error('e: ', err);
    }
  }

  const loadScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
  };

  return ''
};
