import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit'; 
import LoggedInIntercomeMessenger from './content/messenger/LoggedInIntercomeMessenger';
import AnonymousIntercomeMessenger from './content/messenger/AnonymousIntercomeMessenger';
import ChatWidget from './content/liveChat/LiveChat';

require('dotenv').config();

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err){ // response interceptor
  const backEndErrorCode = err?.response?.data?.code;

  if(backEndErrorCode && backEndErrorCode === 'TOKEN_ERROR'){
    localStorage.removeItem('activeShop');
    localStorage.removeItem('shops');
    localStorage.removeItem('user'); 
    localStorage.removeItem('orders');
    window.location.href = window.location.origin;
    return Promise.reject(err);
  }
  return Promise.reject(err);
});

export default function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  const { user } = useAuth();

  return (
    <ThemeProvider>      
      <ChatWidget />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized ? content : <AppInit />}
          {user ? <LoggedInIntercomeMessenger /> : <AnonymousIntercomeMessenger /> }
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}