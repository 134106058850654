import { createSlice } from '@reduxjs/toolkit';

export const CHAT_WIDGET_SLICE_NAME = 'chatWidgets';

const initialState = ''

export const chatWidgetsSlice = createSlice({
  name: CHAT_WIDGET_SLICE_NAME,
  initialState,
  reducers: {
    chatWidgetReducer: (state = initialState, actions) => {
        console.log('s:', state);
      return actions.payload;
    },
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { chatWidgetReducer } = chatWidgetsSlice.actions

export default chatWidgetsSlice.reducer