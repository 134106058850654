import { Zoom } from '@mui/material';
// import axios from 'axios';

export const getErrorMessage = (err) => {
  const data = Array.isArray(err) ? err : err?.response?.data;
  console.log('d: ', data);
  let msg = data && Array.isArray(data) ? '' : typeof data === 'object' && data.message ? data.message : 'Sorry, something went wrong';

  if(Array.isArray(data)){
    data.filter((item, i)=>{
      msg += i<data.length - 1 ? `${item.msg} and ` : `${item.msg} `;
      return item.msg;
    });
  }

  return msg;
}

export const getActiveShop = () => {
  let activeShop = localStorage.getItem('activeShop');
  activeShop = JSON.parse(activeShop);
  return activeShop || {};
}

export const getUrlSegment = (segmentNo = 0) => {
// Get the path from the URL
const path = window.location.pathname;

// Split the path into segments
const segments = path.split('/').filter(Boolean); // filter(Boolean) removes empty strings

// Get the first segment
const segment = segments.length > 0 ? segments[segmentNo] : '';
return segment;
}
 
export const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';

export const handleAlert = ({ enqueueSnackbar, t }, { message, type = 'info', vertical = 'bottom', horizontal = 'center' }) => {
  enqueueSnackbar(t(message), {
      variant: type,
      anchorOrigin: {
          vertical,
          horizontal,
      },
      TransitionComponent: Zoom,
  });
};

